var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "avue-dialog",
      attrs: { title: "问题登记", visible: _vm.modelShow, width: "80%" },
      on: {
        "update:visible": function ($event) {
          _vm.modelShow = $event
        },
      },
    },
    [
      _c("div", { staticClass: "formContentBox" }, [
        _c(
          "div",
          { staticClass: "formMain" },
          [
            _c(
              "el-form",
              {
                ref: "dataForm",
                attrs: {
                  model: _vm.dataForm,
                  "label-width": "150px",
                  rules: _vm.formType == "view" ? {} : _vm.rules,
                  disabled: _vm.formType == "view",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "隐患编号", prop: "hdCode" } },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled: "",
                                placeholder: "隐患编号自动生成",
                              },
                              model: {
                                value: _vm.dataForm.hdCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "hdCode", $$v)
                                },
                                expression: "dataForm.hdCode",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "隐患地点", prop: "location" } },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: {
                                  placeholder: "请填写隐患地点",
                                  title: _vm.dataForm.location,
                                },
                                model: {
                                  value: _vm.dataForm.location,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "location", $$v)
                                  },
                                  expression: "dataForm.location",
                                },
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _c("div", {
                                    staticClass: "el-icon-map-location",
                                    staticStyle: {
                                      width: "54px",
                                      "text-align": "center",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openMap()
                                      },
                                    },
                                  }),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "报告人", prop: "reportUserName" },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请选择报告人" },
                              on: {
                                focus: function ($event) {
                                  return _vm.handleUserModeOpen()
                                },
                              },
                              model: {
                                value: _vm.dataForm.reportUserName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "reportUserName", $$v)
                                },
                                expression: "dataForm.reportUserName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "报告部门",
                              prop: "reportDeptName",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                title: _vm.dataForm.reportDeptName,
                                placeholder: "请选择报告部门",
                              },
                              on: {
                                focus: function ($event) {
                                  return _vm.handleDeptModeOpen()
                                },
                              },
                              model: {
                                value: _vm.dataForm.reportDeptName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "reportDeptName", $$v)
                                },
                                expression: "dataForm.reportDeptName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "报告时间", prop: "reportDate" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "datetime",
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                placeholder: "请选择报告时间",
                              },
                              model: {
                                value: _vm.dataForm.reportDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "reportDate", $$v)
                                },
                                expression: "dataForm.reportDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "隐患描述", prop: "hdDesc" } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                autosize: { minRows: 2 },
                                "show-word-limit": "",
                                maxlength: "225",
                                placeholder: "请填写隐患描述",
                              },
                              model: {
                                value: _vm.dataForm.hdDesc,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "hdDesc", $$v)
                                },
                                expression: "dataForm.hdDesc",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "隐患现场", prop: "treeData" } },
                          [
                            _vm.formType != "view"
                              ? _c(
                                  "el-upload",
                                  {
                                    ref: "uploadRef",
                                    staticClass: "upload-demo",
                                    staticStyle: {
                                      "margin-bottom": "12px",
                                      display: "flex",
                                      "align-items": "center",
                                    },
                                    attrs: {
                                      accept: ".jpg,.mp4,.png",
                                      action:
                                        "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km",
                                      "show-file-list": false,
                                      "before-upload": _vm.checkFileType,
                                      "on-success": _vm.handleAvatarSuccess,
                                      headers: _vm.headers,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "small",
                                          type: "primary",
                                          icon: "el-icon-upload",
                                        },
                                      },
                                      [_vm._v("点击上传\n                    ")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "content" },
                              _vm._l(_vm.treeData, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: item.id,
                                    staticClass: "img-content",
                                    on: {
                                      click: function ($event) {
                                        return _vm.getNodeClick(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: item.thumbnailLink,
                                        alt: "",
                                      },
                                    }),
                                    _vm.formType !== "view"
                                      ? _c("i", {
                                          staticClass:
                                            "el-icon-circle-close delete-icon",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.handleRemove(
                                                item,
                                                index
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                )
                              }),
                              0
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            title: "人员选择",
            visible: _vm.deptShow,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.deptShow = $event
            },
          },
        },
        [
          _c("UserDetpDialog", {
            ref: "UserDetpDialog",
            on: { "select-data": _vm.selectData },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            title: "部门选择",
            visible: _vm.deptShow1,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.deptShow1 = $event
            },
          },
        },
        [
          _c("DeptDialog", {
            ref: "DeptDialog",
            on: { "select-data": _vm.selectData1 },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择位置",
            "append-to-body": "",
            visible: _vm.mapBox,
            top: "7vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.mapBox = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { width: "100%" } },
            [
              _vm.mapBox
                ? _c("GaodeMap", {
                    ref: "GaodeMap",
                    on: {
                      selectAddress: _vm.selectAddress,
                      getLocation: _vm.getLocation,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.celMap } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleMapSave },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "avue-dialog__footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.modelShow = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveData()
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }